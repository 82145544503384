import { Card } from "react-bootstrap";
import classNames from 'classnames';
import { Highlighter } from "react-bootstrap-typeahead";

const FormInstrumentsDisplay = ({ option, searchText, className }: any) => {

    const monedaTemplate = () => (
        <>
            <strong className='me-3'>Moneda: </strong> <span className="float-end">{option?.denomination}</span> <br />
        </>
    );

    const defaultTemplate = () => (
        <div key={option.id + '-noti'} className={classNames('notify-item shadow-none read-noti', className)}>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <div className="avatar-xs">
                        <span className="avatar-title rounded"></span>
                    </div>
                </div>
                <div className="flex-grow-1 ms-2">
                    <div className="noti-item-title fw-semibold font-14">
                        <Highlighter search={searchText}>{option?.denomination}</Highlighter>
                    </div>
                    <small className="noti-item-subtitle text-muted">
                        {option?.isinTicker &&
                            (
                                <small className="me-1">
                                    Isin: <i><Highlighter search={searchText}>{option?.isinTicker}</Highlighter></i>
                                </small>
                            )}
                        {option?.symbol &&
                            (
                                <small className="me-1">
                                    Symbol: <i><Highlighter search={searchText}>{option?.symbol}</Highlighter></i>
                                </small>
                            )}
                    </small>
                </div>
            </div>
        </div>
    );

    //TODO: implementar template custom por tipo de instrumento, pero siguiendo un mismo lineamiento
    return (
        <>
            {
                defaultTemplate()
            }
        </>
    );
};

export default FormInstrumentsDisplay;

import React, { Suspense, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useGoogleAnalytics, useRedux } from 'hooks';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { EIPageTitle } from 'components';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/LoginWithAuth0'));
const Logout = React.lazy(() => import('pages/account/Logout'));

// pages
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));

// landing
const EscoInversionesLanding = React.lazy(() => import('pages/escoInversionesLanding/'));
const HomePage = React.lazy(() => import('pages/homePageClient/'));
const CarteraDetail = React.lazy(() => import('pages/escoInversionesLanding/carteraDetail/'));

//dashboard
const DashboardComercial = React.lazy(() => import('pages/ajustes/dashboard/DashboardComercial'));

//ajustes
const ABMCarteras = React.lazy(() => import('pages/ajustes/administracion/carteras'));
const ABMGrupos = React.lazy(() => import('pages/ajustes/administracion/groups/ABMGrupos'));
const ABMUsers = React.lazy(() => import('pages/ajustes/administracion/users'));
const ABMComercial = React.lazy(() => import('pages/ajustes/administracion/comercial'));
const ABMClients = React.lazy(() => import('pages/ajustes/administracion/clients'));
const MyClient = React.lazy(() => import('pages/ajustes/administracion/myClient'));
const MyUser = React.lazy(() => import('pages/ajustes/administracion/myUser'));

//gestion
const RegistroOperacion = React.lazy(() => import('pages/gestion/registroOperaciones/RegistroOperacion'));
const MovimientosGrid = React.lazy(() => import('pages/gestion/movimientosGrid'));
const Cotizaciones = React.lazy(() => import('pages/gestion/cotizaciones/Cotizaciones'));
const ClientAccounts = React.lazy(() => import('pages/gestion/clientAccounts/ClientAccounts'));
const CuentaCorriente = React.lazy(() => import('pages/gestion/ctacte/CuentaCorriente'));

//gestion
const ReportePosicion = React.lazy(() => import('pages/reporte/reportePosicion/ReportePosicion'));
const ReporteRendimiento = React.lazy(() => import('pages/reporte/reporteRendimiento/ReporteRendimiento'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();
    const { initPageTracking } = useGoogleAnalytics();

    useEffect(() => {
        initPageTracking();
    }, []);

    const { layout, layoutColor } = appSelector((state) => ({
        layout: state.Layout,
        layoutColor: state.Layout.layoutColor,
    }));

    useEffect(() => {
        toggleTheme(layoutColor);
    }, [layoutColor]);

    const { switcher, currentTheme, status, themes } = useThemeSwitcher();
    const toggleTheme = (theme: string) => {
        let newTheme: string = theme == 'dark' ? themes.dark : themes.light;
        switcher({ theme: newTheme });
    };

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'landing',
                    element: <Navigate to={'/escoInversionesLanding'} replace />
                },
                {
                    path: 'escoInversionesLanding',
                    element: (
                        <Suspense fallback={loading()}>
                            <EscoInversionesLanding />
                        </Suspense>
                    )
                },
                {
                    path: 'carteraDetail',
                    element: (
                        <Suspense fallback={loading()}>
                            <CarteraDetail />
                        </Suspense>
                    )
                },
                {
                    path: 'reportes',
                    children: [
                        {
                            path: 'cotizaciones',
                            element: (
                                <Suspense fallback={loading()}>
                                    {<Cotizaciones />}
                                </Suspense>
                            )
                        },
                    ]
                },
                {
                    path: 'historico',
                    element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                },
                {
                    // auth protected routes
                    path: 'ajustes',
                    children: [
                        {
                            path: 'parametry',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'admin',
                            children: [
                                {
                                    path: 'myclient',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <MyClient />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'clients',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <ABMClients />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'users',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <ABMUsers />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'myuser',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <MyUser />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'comercial',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <ABMComercial />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'porfolios',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <ABMCarteras />
                                        </Suspense>
                                    )
                                },
                            ]
                        },
                        {
                            path: 'einv',
                            element: <LoadComponent component={ErrorPageNotFound} />,
                        },
                    ]
                },
            ],
        },
        {
            // auth protected routes
            path: '/comercial',
            element: <PrivateRoute roles={'Comercial'} component={Layout} />,
            children: [
                {
                    path: 'landing',
                    element: <Navigate to={'/comercial/dashboard'} replace />
                },
                {
                    path: '/comercial/dashboard',
                    element: (
                        <Suspense fallback={loading()}>
                            <DashboardComercial />
                        </Suspense>
                    )
                },
                {
                    // auth protected routes
                    path: 'gestion',
                    children: [
                        {
                            path: 'clients',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMClients />
                                </Suspense>
                            )
                        },
                        {
                            path: 'users',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMUsers />
                                </Suspense>
                            )
                        },
                        {
                            path: 'myuser',
                            element: (
                                <Suspense fallback={loading()}>
                                    <MyUser />
                                </Suspense>
                            )
                        },
                        {
                            path: 'comercial',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMComercial />
                                </Suspense>
                            )
                        }
                    ]
                },
                {
                    path: 'reports',
                    children: [
                        {
                            path: 'cotizaciones',
                            element: (
                                <Suspense fallback={loading()}>
                                    {<Cotizaciones />}
                                </Suspense>
                            )
                        },
                    ]
                },
                {
                    path: 'ajustes',
                    children: [
                        {
                            path: 'parametry',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                    ]
                },
            ],
        },
        {
            // auth protected routes
            path: '/accountHolder',
            element: <PrivateRoute roles={'AccountHolder'} component={Layout} />,
            children: [
                {
                    path: 'landing',
                    element: <Navigate to={'/accountHolder/home'} replace />
                },
                {
                    path: 'home',
                    element: (
                        <Suspense fallback={loading()}>
                            <HomePage />
                        </Suspense>
                    )
                },
                {
                    // auth protected routes
                    path: 'miPerfil',
                    children: [
                        {
                            path: 'myclient',
                            element: (
                                <Suspense fallback={loading()}>
                                    <MyClient />
                                </Suspense>
                            )
                        },
                        {
                            path: 'users',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMUsers />
                                </Suspense>
                            )
                        },
                        {
                            path: 'clientAccounts',
                            element: (
                                <Suspense fallback={loading()}>
                                    {<ClientAccounts />}
                                </Suspense>
                            )
                        },
                        {
                            path: 'myuser',
                            element: (
                                <Suspense fallback={loading()}>
                                    <MyUser />
                                </Suspense>
                            )
                        },
                        {
                            path: 'parametry',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                    ]
                },
                {
                    // auth protected routes
                    path: 'gestion',
                    children: [
                        {
                            path: 'movimientos',
                            children: [
                                {
                                    path: '',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <MovimientosGrid />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'alta-manual',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            {<RegistroOperacion />}
                                        </Suspense>
                                    )
                                },
                            ]
                        },
                        {
                            path: 'cotizaciones',
                            element: (
                                <Suspense fallback={loading()}>
                                    {<Cotizaciones />}
                                </Suspense>
                            )
                        },
                        {
                            path: 'ctacte',
                            element: (
                                <Suspense fallback={loading()}>
                                    <CuentaCorriente />
                                </Suspense>
                            )
                        }
                    ]
                },
                {
                    path: 'carteras',
                    children: [
                        {
                            path: 'porfolios',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMCarteras />
                                </Suspense>
                            )
                        },
                        {
                            path: 'porfolios_groups',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ABMGrupos />
                                </Suspense>
                            )
                        }
                    ]
                },
                {
                    path: 'reports',
                    children: [
                        {
                            path: 'posicion',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ReportePosicion />
                                </Suspense>
                            )
                        },
                        {
                            path: 'rendimiento',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ReporteRendimiento />
                                </Suspense>
                            )
                        },
                    ]
                },
            ],
        },
        {
            // auth protected routes
            path: '/users',
            element: <PrivateRoute roles={'Users'} component={Layout} />,
            children: [
                {
                    path: 'landing',
                    element: <Navigate to={'/users/home'} replace />
                },
                {
                    path: 'home',
                    element: (
                        <Suspense fallback={loading()}>
                            <HomePage />
                        </Suspense>
                    )
                },
                {
                    path: 'gestion',
                    children: [
                        {
                            path: 'movimientos',
                            children: [
                                {
                                    path: '',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <MovimientosGrid />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'alta-manual',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            {<RegistroOperacion />}
                                        </Suspense>
                                    )
                                },
                            ]
                        },
                        {
                            path: 'cotizaciones',
                            element: (
                                <Suspense fallback={loading()}>
                                    {<Cotizaciones />}
                                </Suspense>
                            )
                        },
                        {
                            path: 'ctacte',
                            element: (
                                <Suspense fallback={loading()}>
                                    <CuentaCorriente />
                                </Suspense>
                            )
                        }
                    ]
                },
                {
                    path: 'myportfolio',
                    element: (
                        <EIPageTitle className='page-header-fixed'
                            title={<span>Mis Carteras</span>}
                        />
                    )
                },
                {
                    path: 'myuser',
                    element: (
                        <Suspense fallback={loading()}>
                            <MyUser />
                        </Suspense>
                    )
                },
                {
                    path: 'parametry',
                    element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                },
                {
                    // auth protected routes
                    path: 'gestion',
                    children: [
                        {
                            path: 'movimientos',
                            children: [
                                {
                                    path: '',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            <MovimientosGrid />
                                        </Suspense>
                                    )
                                },
                                {
                                    path: 'alta-manual',
                                    element: (
                                        <Suspense fallback={loading()}>
                                            {<RegistroOperacion />}
                                        </Suspense>
                                    )
                                },
                            ]
                        },
                    ]
                },
                {
                    // auth protected routes
                    path: 'reports',
                    children: [
                        {
                            path: 'posicion',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ReportePosicion />
                                </Suspense>
                            )
                        },
                        {
                            path: 'rendimiento',
                            element: (
                                <Suspense fallback={loading()}>
                                    <ReporteRendimiento />
                                </Suspense>
                            )
                        },
                    ]
                },
            ],
        },
    ]);
};

export { AllRoutes };

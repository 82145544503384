import { ApexOptions } from "apexcharts";
import { Languages, einvDateFormat } from "appConstants";


export function currencyFormat(num: number | undefined) {
    // let stringNum: string = num?.toFixed(2) || '';
    // return '$' + stringNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    var result = num !== undefined ? new Intl.NumberFormat('es-ar', { style: 'currency', currency: 'ARS', minimumFractionDigits: 0 }).format(num) : '';
    return result;
}

export const noData: ApexNoData = {
    text: 'No existe información para mostrar',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
        color: undefined,
        fontSize: '24px',
        fontFamily: undefined
    }
};


export const defaultAreaOptions: ApexOptions = {
    theme: {
        mode: 'dark'
    },
    noData: noData,
    chart: {
        animations: {
            enabled: false,
        },
        stacked: false,
        height: 350,
        zoom: {
            enabled: false
        },
        toolbar: {
            autoSelected: 'zoom'
        },
        background: 'transparent'
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
        },
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
    },
    yaxis: {
        labels: {
            formatter: function (val: number) {
                let value = currencyFormat(val);
                return value;
            },
        }
    },
    xaxis: {
        type: 'datetime',
        labels: {
            format: einvDateFormat
        }
    },
    tooltip: {
        y: {
            formatter: function (val: number) {
                let value = currencyFormat(val);
                return value;
            }
        },
        x: {
            format: einvDateFormat
        },
    },
    stroke: {
        width: 1
    }
};

export const defaultPieOptions: ApexOptions = {
    noData: noData,
    chart: {
        height: 340,
        type: 'pie',
        animations: {
            enabled: false,
        },
    },
    colors: ['#727cf5', '#0acf97', '#fa5c7c', '#ffbc00', '#aabc00'],
    legend: {
        show: true,
    },
    dataLabels: {
        enabled: true
    },
    tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return '<div class="arrow_box">' +
                '<span>' + w.globals.labels[seriesIndex] + '</span>' +
                '</div>'
        }
    }
};

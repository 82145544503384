import { useRedux } from 'hooks';
import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { addModal, deleteModal } from 'redux/modals/actions';

type EIModalProps = {
    isActive?: boolean;
    title?: string;
    headerControls?: React.ReactNode;
    children?: React.ReactNode;
    onHide?: () => void;
    onShow?: () => void;
    toggleModal?: () => void;
    className?: string;
    scroll?: boolean;
    modalProps?: ModalProps
}

const EIModal = (props: EIModalProps) => {
    const { dispatch, appSelector } = useRedux();
    const { currentZIndex } = appSelector((state) => ({
        currentZIndex: state.Modals.currentZIndex,
    }));

    const interHandleShow = () => {
        dispatch(addModal());
        if (props?.onShow) {
            props.onShow();
        }
    };

    const interHandleClose = () => {
        dispatch(deleteModal());
        if (props.toggleModal) {
            props.toggleModal();
        }
        if (props?.onHide) {
            props.onHide();
        }
    };

    return (
        <Modal {...props.modalProps} scroll={props.modalProps?.scrollable} onHide={interHandleClose} onShow={interHandleShow} style={{ 'zIndex': currentZIndex }} backdropClassName={'z-index-' + currentZIndex}>
            <Modal.Header onHide={interHandleClose} closeButton>
                <h4 className="modal-title">{props.title}</h4>
                <div className="float-end">
                    {props.headerControls}
                </div>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    );
};
export default EIModal;
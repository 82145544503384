import { einvApiDateFormat, einvMomentDateFormat } from "appConstants";
import moment, { Moment } from "moment";

type DownloadFile = {
    data: any;
    filename: string;
    mime: any;
    bom: any;
};

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    var blobURL =
        window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200);
};

const odataInstrumentsSearchQuery = (query: string): string => {
    return `contains(tolower(Symbol), tolower('${query}')) or contains(tolower(Name), tolower('${query}')) or contains(tolower(Denomination), tolower('${query}')) or contains(tolower(IsinTicker), tolower('${query}')) or contains(tolower(InstrumentsType/Description), tolower('${query}'))`;
};

const formatDateTime = (date: Date | string | Moment | null | undefined): string | null => {
    if (!date) {
        return null;
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format();
};

const formatReadableDateTime = (date: Date | string | Moment | null | undefined, format: string | undefined = einvMomentDateFormat): string | null => {
    if (!date) {
        return null;
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format(format);
};


const formatDateForApiQuery = (date: Date | string | Moment | null | undefined): string => {
    if (!date) {
        return '';
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format(einvApiDateFormat);
};

const parseDateString = (value: string): Date | null => {
    let today: Date = new Date();
    if (!value) {
        return null;
    }

    //Se utilizar minutos y segundos estandarizados a fines de comparación entre fechas
    let momentValue = moment(value)
        .set('hour', today.getHours())
        .set('minute', today.getMinutes())
        .set('second', today.getSeconds())
        .set('millisecond', today.getMilliseconds());

    if (!momentValue.isValid()) {
        return null;
    }
    return momentValue.toDate();
};

const sortArrayByDateProperty = (array: any[], dateProperty: string): any[] => {
    return array.sort((a, b) => new Date(a[dateProperty]).getTime() - new Date(b[dateProperty]).getTime());
}

// Funcion genérica para ordenar arrays de objetos por una propiedad (especificada por parámetro)
function createCompareFn<T extends Object>(
    property: keyof T,
    sort_order: "asc" | "desc"
  ) {
    const compareFn = (a: T, b: T):number => {        
      const val1 = a[property] as unknown;
      const val2 = b[property] as unknown;
      const order = sort_order !== "desc" ? 1 : -1;
  
      switch (typeof val1) {
        case "number": {
          const valb = val2 as number;
          const result = val1 - valb;
          return result * order;
        }
        case "string": {
          const valb = val2 as string;
          const result = val1.localeCompare(valb);
          return result * order;
        }
        // add other cases like boolean, etc.
        default:
          return 0;
      };
    }

    return compareFn;
  }

export { downloadFile, odataInstrumentsSearchQuery, formatDateTime, formatReadableDateTime, parseDateString, sortArrayByDateProperty, formatDateForApiQuery, createCompareFn };

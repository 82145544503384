/* numbers */

import { NumberFormatOptions } from "@telerik/kendo-intl";

export const einv_locale = 'es-AR';
// export const einv_locale = 'en-US';

export const currencyFieldDefaultFormat = 'c10';
// export const currencyFieldDefaultFormat = '#.0000000000';
export const currencyLabelDefaultFormat = 'c10';
export const numberFieldDefaultFormat = 'n4';
export const numberLabelDefaultFormat = 'n4';

export const einvApiDateFormat = 'YYYY-MM-DD';
export const einvDateFormat = 'dd/MM/yyyy';
export const einvMomentDateFormat = 'DD/MM/YYYY';
export const einvMomentDateTimeFormat = 'DD/MM/YYYY hh:mm';

export const kendoCurrencyDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 10,
};

//Formato para campos currency en los que no se muestra mucha pre
export const kendoCurrencyLowPresitionDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
};

//Formato para campos currency en los que se requiere que se muestren 2 decimales, incluso si el numero es entero
export const kendoCurrencyFixed2DecimalPositionsFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
};

export const kendoNumberDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 4,
};

//Lista de colores disponibles para graficos de torta
// export const pieChartAvailableColors: string[] = ['#fc0f03', '#35fc03', '#fc8c03', '#6ab6f7', '#696501', '#a503fc', '#030ffc', '#4a03fc', '#ccbbb8', '#b7fc97'];
export const pieChartAvailableColors: string[] = ["#C3BEF7", "#B0A7E8", "#9D91D9", "#8A7BCA", "#7766BC", "#6351AD", "#4F3D9F", "#392890", "#1D1482"];
// export const pieChartAvailableColors: string[] = ["#0077b0", "#3f7784", "#7e7758", "#bc772c", "#fb7700"];
// export const pieChartAvailableColors: string[] = ["#1d1482", "#162da1", "#0f46bf", "#075fde", "#0078fc"];

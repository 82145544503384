import { GridCellProps, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import popOver from "components/Popover";
import classNames from 'classnames';

type ActionButtonProps = {
    text: string;
    iconClass: string;
    linkClass?: string;
    onClick?: (dataItem: any) => void;
    disabled?: boolean;
};
export const ActionButton = (props: ActionButtonProps) => {
    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popOver(props.text)}>
            <Link to="#" onClick={props.onClick} className={classNames('action-icon', { 'disabled-link': props.disabled })} >
                {' '}
                <i className={classNames('font-20', props.iconClass, { 'disabled-action-button-icon': props.disabled })}></i>
            </Link>
        </OverlayTrigger>
    );
};

export const ActionColumn = (cell: any) => {
    const navigationAttributes = useTableKeyboardNavigation(cell.id);
    return (
        <td
            style={{ textAlign: 'center', ...cell.style }} // this applies styles that lock the column at a specific position
            className={cell.className} // this adds classes needed for locked columns
            colSpan={cell.colSpan}
            role={"gridcell"}
            aria-colindex={cell.ariaColumnIndex}
            aria-selected={cell.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: cell.columnIndex,
            }}
            {...navigationAttributes}
        >
            {cell.children}
        </td>
    );
};


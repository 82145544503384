import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { parseDateString } from "helpers/api/utils";
import { einvDateFormat } from "appConstants";

const today = new Date();
const FormDateField = ({ as, md, controlId, label, name, type, inputGroupPrepend, filterDate, minDate, maxDate, onChange, mostrarErrores, disabled }: any) => {
    const onDateChange = (value: string, form: any): Date => {
        let defaultValue: Date = today;
        if (!value) {
            form.setFieldValue(name, defaultValue);
            return defaultValue;
        }

        let aux = parseDateString(value);
        if (!aux) {
            form.setFieldValue(name, defaultValue);
            return defaultValue;
        }
        return aux;
    };

    return (
        <Field
            name={name}
            render={({ field, form, meta }: any) => {
                const isValid = !form.errors[field.name];
                const isInvalid = (meta.touched || form.submitCount > 0) && meta.error && !isValid;
                return (
                    <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
                        <Form.Label>{label}</Form.Label>
                        <InputGroup>
                            {inputGroupPrepend}
                            <DatePicker
                                customInput={
                                    <input
                                        type="text"
                                        className={classNames('form-control date w-100', isInvalid || (mostrarErrores && meta.error) ? 'is-invalid' : '')}
                                        value={meta.value}
                                    />}
                                className={'form-control'}
                                selected={onDateChange(meta.value, form)}
                                dayClassName={(date: Date) =>
                                    today.toDateString() === date.toDateString() ? 'datepicker-today-custom-class' : ''
                                }
                                onBlur={() => {
                                    form.getFieldHelpers(name).setTouched(true, true);
                                }}
                                onChange={(date) => {
                                    form.setFieldValue(name, date);
                                    if (onChange) {
                                        onChange(date, form);
                                    }
                                }}
                                timeFormat='hh:mm a'
                                dateFormat={einvDateFormat}
                                minDate={minDate}
                                maxDate={maxDate}
                                filterDate={filterDate}
                                autoComplete="off"
                                disabled={disabled}
                            />
                            <Form.Control.Feedback type="invalid" style={{ display: isInvalid || mostrarErrores ? 'block' : 'none' }}>
                                {meta.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                );
            }}
        />
    );
};

FormDateField.defaultProps = {
    type: "text",
    inputGroupPrepend: null
};

export default FormDateField;

import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useRedux } from 'hooks';

import { defaultAreaOptions, defaultPieOptions } from './defaultChartsValues';
import { useTranslation } from 'react-i18next';
import { PortFolioSumarizedType } from 'types/PortFolioSumarizedType';
import { PieChartInfoType } from 'types/PieChartInfoType';

type StatisticsChartWidgetCarteraGroupProps = {
    sumarizedData?: PortFolioSumarizedType;
    colors?: Array<string>;
};

const StatisticsChartWidgetCarteraGroup = ({
    sumarizedData,
    colors,
}: StatisticsChartWidgetCarteraGroupProps) => {
    const { t } = useTranslation();
    const { appSelector } = useRedux();
    const { langName } = appSelector((state: any) => ({
        langName: state.Layout.langName
    }));

    //Pie charts
    let pieOpts: ApexOptions = getPieOptions(sumarizedData?.composicionByCartera || []);
    let pieOptsTiposActivo: ApexOptions = getPieOptions(sumarizedData?.composicionByTpActivo || []);

    //Evolution charts
    let areaOptions: ApexOptions = {
        ...defaultAreaOptions,
        series: getData(sumarizedData || {}),
        fill: {
            ...defaultAreaOptions.fill,
            colors: colors,
        },
        yaxis: {
            ...defaultAreaOptions.yaxis,
            tickAmount: 2,
            title: {
                text: t('Valuacion')
            }
        },
        xaxis: {
            ...defaultAreaOptions.xaxis,
            title: {
                text: t('Evolucion')
            }
        },
    };

    //aux functions
    function getData(_sumarizedData: PortFolioSumarizedType): ApexAxisChartSeries {
        let values = _sumarizedData?.evolution?.map(info => {
            return {
                x: info.date,
                y: info.value
            };
        });

        return [{
            name: _sumarizedData?.name,
            color: colors ? colors[0] : '',
            data: values as { x: any; y: any; fillColor?: string; strokeColor?: string; meta?: any; goals?: any; }[]
        }];
    }

    function getPieOptions(pie: PieChartInfoType[]): ApexOptions {
        return {
            ...defaultPieOptions,
            labels: pie.map(x => x.name || ''),
            series: pie.map(x => x.value || 0),
        };
    }

    const [agrupadoPorCartera, setAgrupadoPorCartera] = useState<boolean>(true);;
    function onClickChangeActiveType() {
        setAgrupadoPorCartera(!agrupadoPorCartera);
    }

    function renderCharts() {
        return (
            <Col className="col-12">
                <Row>
                    <Col className="col-7">
                        <Chart
                            className="apex-charts"
                            options={areaOptions}
                            series={areaOptions.series}
                            height={300}
                            type='area'
                        />
                    </Col>
                    <Col className="col-5 ">
                        <div className="w-100">
                            <Form.Check
                                type="switch"
                                id={sumarizedData?.id + "-switch"}
                                className="w-300 text-start"
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}
                                label={agrupadoPorCartera ? t('Agrupado por Carteras') : t('Agrupado por Tipo de activo')} onClick={onClickChangeActiveType} />
                        </div>
                        <Chart
                            options={agrupadoPorCartera ? pieOpts : pieOptsTiposActivo}
                            series={agrupadoPorCartera ? pieOpts.series : pieOptsTiposActivo.series}
                            type="pie"
                            height={200}
                            className="apex-charts mb-4 mt-4"
                        />
                    </Col>
                </Row>
            </Col>
        );

    }

    return renderCharts();
};

export default StatisticsChartWidgetCarteraGroup;


import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { useTranslation } from 'react-i18next';
import { useApiCalls } from "helpers/api/useApiCalls";
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import FormInstrumentsItem from "./form-instruments-item";
import useResolveInstrumentDisplayLabel from "./form-instruments-display";
import { ItemsValuesContext } from "./ItemsValuesContext";

let currentQuery: string | null = null;
const TypeheadInstruments = ({ as, md, controlId, label, name, type, inputGroupPrepend, values, containerClass, selectedItem, onSearch, items, onChange, size }: any) => {
    const { t } = useTranslation();
    const { itemsValues, setItemValue, formikProps } = useContext(ItemsValuesContext);
    const apiCalls = useApiCalls()
    const defaultRef = useRef();
    const { getDisplayLabel } = useResolveInstrumentDisplayLabel();
    const resolvedRef: any = null || defaultRef;
    // const [currentQuery, setCurrentQuery] = useState<string | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [shownResults, setShownResults] = useState<number | null>(null);
    const [options, setOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any | null>(null);

    //al cargarse el componente
    useEffect(() => {
        if (values[name]) {
            setSelectedOption(itemsValues[name]);
        }
    }, []);

    //al iniciarse typeahead
    useEffect(() => {
        if (resolvedRef.current) {
            if (values[name]) {
                resolvedRef.current.setState({
                    selected: [options.filter((x: any) => x.id == values[name])[0]]
                });
            }
            else {
                resolvedRef.current.focus();
                if (!isSearching) {
                    handleSearch('');
                }
            }
        }
    }, [resolvedRef.current]);

    const handleSearch = (query: string) => {
        setIsSearching(true);

        let _builder: string[] = [];

        //string match query
        if (query) {
            _builder.push(`contains(tolower(Symbol), tolower('${query}')) or contains(tolower(Name), tolower('${query}')) or contains(tolower(Denomination), tolower('${query}')) or contains(tolower(IsinTicker), tolower('${query}')) or contains(tolower(InstrumentsType/Description), tolower('${query}'))`);
        }
        //instrument type
        if (values.instrumentTypeId) {
            _builder.push(`InstrumentsType/Id eq ${values.instrumentTypeId}`);
        }
        //market
        if (values.marketId) {
            _builder.push(`Markets/Id eq ${values.marketId}`);
        }

        //build query
        let finalQuery = _builder.map(x => `(${x})`).join(' and ');

        currentQuery = finalQuery;

        //Api call
        apiCalls.Instrumentos.getInstrumentsOData({ /*$top: 1000, */$orderby: 'Denomination', $filter: finalQuery }).then((response: any) => {
            if (finalQuery == currentQuery) {
                setOptions(response.data);
                setShownResults(10);
                if (onSearch) {
                    onSearch(response.data);
                }
            }
        }).finally(() => {
            if (finalQuery == currentQuery) {
                if (!resolvedRef.current.isMenuShown) {
                    resolvedRef.current.toggleMenu();
                }
                setIsSearching(false);
            }
        });
    };

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = (a: any) => true;

    return (
        <>
            <Field name={name} render={({ field, form, meta }: any) => {
                return (<Form.Group className={containerClass}>
                    <Form.Label>{label}</Form.Label>
                    <InputGroup>
                        {itemsValues.instrumentTypeId && (
                            <InputGroup.Text >
                                <span className="long-text-ellipsis" style={{ width: '100px' }} title={itemsValues?.instrumentTypeId?.description}>{itemsValues?.instrumentTypeId?.description}</span>
                            </InputGroup.Text>
                        )}
                        <AsyncTypeahead
                            id={name}
                            ref={resolvedRef}
                            filterBy={filterBy}
                            isLoading={isSearching}
                            onSearch={handleSearch}
                            options={options}
                            paginate={true}
                            onPaginate={(e: any, _shownResults: number) => {
                                setShownResults(_shownResults);
                            }}
                            useCache={false}
                            maxResults={10}
                            paginationText={(<>
                                <div className="d-flex">
                                    <div className="flex-grow-1">Mostrar más datos...</div>
                                    <i>
                                        Viendo {shownResults || 10} de {options?.length}
                                    </i>
                                </div>
                            </>
                            )}
                            searchText={'Buscando...'}
                            clearButton={true}
                            promptText={isSearching ? 'Buscando...' : 'Ingrese Symbol, Ticker o Descripción para iniciar la búsqueda...'}
                            emptyLabel={'No se encontraron resultados'}
                            size={size}
                            minLength={0}
                            defaultInputValue={getDisplayLabel(itemsValues[name]) || ''}
                            onChange={(selectedOptions: any) => {
                                form.getFieldHelpers(name).setTouched(true, false);
                                let selected: any = selectedOptions[0];
                                setSelectedOption(selected);
                                form.setFieldValue(name, selected?.id);
                                setItemValue(name, selected);
                                if (onChange) {
                                    onChange(selected);
                                }
                            }}
                            onBlur={() => {
                                form.getFieldHelpers(name).setTouched(true, false);
                            }}
                            isInvalid={meta.error}
                            renderMenuItemChildren={(option: any, { text }: any) => (<FormInstrumentsItem className='mb-2' option={option} searchText={text} />)}
                            labelKey={(option: any) => { return getDisplayLabel(option); }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: meta.error ? 'block' : 'none' }}>
                            {meta.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                );
            }}
            />
        </>
    );
};

const FormInstrumentsField = TypeheadInstruments;

export default FormInstrumentsField;
